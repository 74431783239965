import store from '@/store';
import { ErrorType } from "../utils/types";
export const defaultHeaders = {
  "Content-Type": "application/json"
};
export type RequestOptions = {
  method?: "GET" | "PUT" | "POST" | "DELETE";
  headers?: Record<string, string>;
  body?: string | object;
  query?: string | object;
  blob?: boolean;
}
export const serialize = (obj: Record<string, any> | string): string => {
  if (typeof obj === "string") return obj;
  const str = [];
  for (const p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
export default async function request(url: string, options?: RequestOptions) {
  options = options || {}
  const authHeader = store.getters.auth ? { "Authorization": "Bearer " + store.getters.auth.accessToken } : {};
  const headers = Object.assign({}, defaultHeaders, authHeader, options.headers || {});
  let body = null;
  const query = options.query ? "?" + serialize(options.query) : "";
  if (typeof options.body === "string") body = options.body;
  else if (typeof options.body === "object") body = JSON.stringify(options.body);
  let res;
  try {
    res = await fetch(
      url + query,
      {
        method: options.method || "POST",
        headers: headers,
        body: body
      }
    );
  } catch (err: any) {
    console.error(err);
    throw { error: true, message: ErrorType.UNEXPECTED_SERVER_ERROR };
  }
  if (options.blob) {
    if (res.ok) return await res.blob();
  }
  if (res.ok && res.status === 204) return;
  else if (res.ok && res.status === 200) return await res.json();
  else if (res.status >= 400 && res.status < 500) throw await res.json();
  else throw { error: true, message: ErrorType.UNEXPECTED_SERVER_ERROR };

}