import request from './request'

export default {
  async test(name: string, locale: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/import/test", { method: "POST", body: { name, locale } })
  },
  async responses(id: string, externalId: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/import/responses", { method: "POST", body: { id, externalId } })
  },
  async company(name: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/import/company", { method: "POST", body: { name } })
  },
}