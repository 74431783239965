

const phoneRegex = /^\+?[0-9]+$/i
export function phone(value: string): boolean {
  if (!value) return true
  return phoneRegex.test(value);
}
const usernameRegex = /^[A-z]+[A-z0-9\-\_]$/i
export function username(value: string): boolean {
  if (!value) return true
  return usernameRegex.test(value);
}
const dateRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/i
export function date(value: string): boolean {
  if (!value) return true
  if (!dateRegex.test(value)) return false; // not valid format
  const date = new Date(value);
  if (Number.isNaN(date.getTime())) return false;
  const value2 = date.toISOString().substr(0, 10);
  return value === value2;
}
const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
export function email(value: string): boolean {
  if (!value) return true;
  return emailRegex.test(value);
}
const uppercaseRegex = /[A-Z]+/;
const lowercaseRegex = /[a-z]+/;
const numericRegex = /[0-9]+/;
const specialCharRegex = /[\W]+/;
export function strong(value: string): boolean {
  if (!value) return true;
  const upper = uppercaseRegex.test(value);
  const lower = lowercaseRegex.test(value);
  const numeric = numericRegex.test(value);
  const specialChar = specialCharRegex.test(value);
  return upper && lower && numeric && specialChar;
}
export const emailSplit = /[\s,;]+/gi
export function emailList(value: string) {
  const emails = value.split(emailSplit);
  if (emails.length === 1 && !emails[0].trim()) return true;
  for (const e of emails) {
    if (!email(e)) return false;
  }
  return true;
}