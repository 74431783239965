import { AuthPayload } from "@/store/types";

export function parseJwtPayload(token: string): AuthPayload {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function saveBlob(blob: Blob, fileName: string) {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";

  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function chunkArray(data: any[] = [], size = 10): any[][] {
  const groups = [];
  let group = [];
  for (const e of data) {
    if (!e) continue;
    if (group.length < size) group.push(e);
    if (group.length === size) {
      groups.push(group);
      group = [];
    }
  }
  if (group.length) groups.push(group)
  return groups;
}