import { CompanyRef } from './models/Company'
import { SmartProfile } from './models/SmartProfile'
import { SmartSearchRecord } from './models/SmartSearch'
import { UserRef } from './store/types'

export type RecordSet<T> = {
  page: number;
  total: number;
  pageSize: number;
  records: T[];
}
export type SelectOption = {
  value: any;
  text: string;
}
export type SearchUserForm = {
  surname?: string;
  name?: string;
  companies?: CompanyRef[];
  type?: string;
  inactiveOnly?: boolean;
  noCompany?: boolean;
}
export type SearchFormulaForm = {
  testDef?: TestRef;
}
export type SearchTestResultForm = {
  surname?: string;
  name?: string;
  companies?: CompanyRef[];
  order?: number;
}
export type SearchCampaignForm = {
  name?: string;
  company?: CompanyRef;
  testDef?: TestRef;
}
export type SearchSmartProfileForm = {
  name?: string;
  company?: CompanyRef;
  testDef?: TestRef;
}
export type TestRef = {
  _id: string;
  name: string;
}
export enum TestInstanceStatus {
  CREATED = "CREATED",
  STARTED = "STARTED",
  COMPLETED = "COMPLETED",
  VERIFIED = "VERIFIED"
}
export type TestInstance = {
  _id: string;
  testDef: TestRef;
  points?: Record<string, number>;
  user: UserRef;
  company: CompanyRef;
  createdAt: Date;
  updatedAt: Date;
  startedAt?: Date;
  endedAt?: Date;
  status: TestInstanceStatus;
  results?: Record<string, string>;
  conclusions?: string;
  linesOfManagement?: string;
}
export type QResponseDef = {
  _id: string;
}
export type QuestionDef = {
  _id: string;
  responses: QResponseDef[];
}
export type ResultDef = {
  _id: string;
  externalId?: any;
  min: number;
  max: number;
}
export type GroupDef = {
  _id: string;
  externalId?: any;
  macroGroupId: string;
  pointsAvg: number;
  pointsGood: number;
  scale: number;
  divisor: number;
  results: ResultDef[];
}
export type TestDef = {
  _id: string;
  name: string;
  locales: string[];
  questions: QuestionDef[];
  groups?: GroupDef[];
  valuesScale: number;
}
export type TestLocale = {
  _id: string;
  name: string;
  locale: string;
  questions: Record<string, string>;
  responses: Record<string, string>;
  formulas: Record<string, string>;
}
export type FormulaDef = {
  _id: string;
  formula: string;
  group: number;
}
export type ParameterRef = {
  _id: string;
  name: string;
}

export type EditFormulaPayload = {
  formula: FormulaDef;
  content: string;
}

export type SmartSearchRecordsSet = RecordSet<SmartSearchRecord> & {
  max: number;
  profile?: SmartProfile;
}