var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container page p-0"},[(_vm.$store.getters.user)?_c('app-header'):_vm._e(),_c('app-page-title',[_vm._v(" "+_vm._s(_vm.label("page-smartSearch-title"))+" "),_c('template',{slot:"actions"})],2),_c('div',{staticClass:"content"},[(_vm.error)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_vm._v(" "+_vm._s(_vm.label(_vm.error))+" ")]):_vm._e(),_c('smart-search-form',{attrs:{"smartSearchId":_vm.$route.params.id},on:{"success":_vm.onScucess,"cancel":_vm.onCancel}}),(_vm.$route.params.id)?_c('smart-search-filter-form',{model:{value:(_vm.smartSearchFilter),callback:function ($$v) {_vm.smartSearchFilter=$$v},expression:"smartSearchFilter"}}):_vm._e(),(_vm.$route.params.id)?_c('table',{staticClass:"table table-sm table-light table-striped mb-3"},[_c('thead',[_c('tr',[_c('th',{},[_vm._v("#")]),_c('th',{},[_vm._v(_vm._s(_vm.label("label-name")))]),_c('th',{attrs:{"width":"100%"}}),_c('th',{},[_vm._v(_vm._s(_vm.label("label-score")))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.label("label-actions")))])])]),_c('tbody',[_vm._l((_vm.result.records),function(record){return [_c('tr',{key:record._id,on:{"click":function($event){return _vm.toggleExpanded(record)}}},[_c('td',[(
                  !_vm.smartSearchFilter.category ||
                  _vm.smartSearchFilter.category._id === 'all'
                )?_c('span',[_vm._v(" #"+_vm._s(record.rankAll)+" ")]):(_vm.smartSearchFilter.category._id === 'full')?_c('span',[_vm._v(" #"+_vm._s(record.rankFull)+" ")]):(_vm.smartSearchFilter.category._id === 'partial')?_c('span',[_vm._v(" #"+_vm._s(record.rankPartial)+" ")]):_vm._e()]),_c('td',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(record.user.name)+" "+_vm._s(record.user.surname)+" ")]),_c('td',[_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"bg-danger",staticStyle:{"display":"block","height":"25px","box-sizing":"border-box","position":"absolute"},style:({
                    width: _vm.computeWidth(record),
                    opacity: record.negativeSum > 0 ? 0.5 : 1,
                  })}),(record.negativeSum)?_c('span',{staticClass:"position-absolute smart-result-negative-label"},[_vm._v(" -"+_vm._s(record.negativeSum)+" ")]):_vm._e()])]),_c('td',{staticClass:"pointsCol"},[_c('span',[_vm._v(_vm._s(record.scoreSum))])]),_c('td',[_c('span',{staticClass:"btn",on:{"click":function($event){return _vm.showTest(record)}}},[_c('b-icon',{attrs:{"icon":"eye-fill"}})],1)])]),_c('tr',{key:record._id + '_expanded'},[(record.expanded)?_c('td',{attrs:{"colspan":"100%"}},_vm._l((record.points),function(points,p){return _c('span',{key:p,staticClass:"chart-container m-1",class:{
                  'alert-success': points.negative === 0,
                  'alert-warning': points.negative !== 0,
                }},[_c('span',{staticClass:"chart-title"},[_vm._v(_vm._s(p))]),_c('span',{staticClass:"chart"},[_c('gauge-chart',{attrs:{"responsive":false,"width":150,"height":100,"chart-data":_vm.chartData(points, p),"options":_vm.chartOptions(points, p)}})],1)])}),0):_vm._e()])]}),(!_vm.result.records.length && !_vm.loading)?_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_vm._v(_vm._s(_vm.label("label-records-none")))])]):_vm._e(),(_vm.loading)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"100%"}},[_c('b-spinner',{attrs:{"variant":"primary"}})],1)]):_vm._e()],2),_c('tfoot',{staticClass:"text-center"},[_c('tr',[_c('td',{attrs:{"colspan":"100%"}},[_c('b-pagination',{staticClass:"justify-content-center",attrs:{"total-rows":_vm.result.total,"per-page":_vm.result.pageSize,"aria-controls":"my-table"},model:{value:(_vm.result.page),callback:function ($$v) {_vm.$set(_vm.result, "page", $$v)},expression:"result.page"}})],1)])])]):_vm._e()],1),_c('app-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }