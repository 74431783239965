import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  BAlert,
  BCard,
  BContainer,
  BCollapse,
  BForm,
  BFormRow,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BNavbar,
  BNavbarBrand,
  BNavbarNav,
  BNavbarToggle,
  BDropdownItem,
  BNavItemDropdown,
  BPagination,
  BRow,
  BCol,
  BSpinner,
  BootstrapVueIcons,
  BModal,
  BFormSelect,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormSelectOption,
  BFormCheckboxGroup,
  BOverlay,
  BFormRadio,
  BBadge,
  BFormTextarea,
  BCardHeader,
  BCardFooter,
  BButton
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import * as chartjs from "chart.js";
import * as GaugeChart from "chartjs-gauge";
// import * as gaugeChart from "chartjs-gauge";
console.log("chart.js:", !!chartjs, !!GaugeChart);
import Vuelidate from 'vuelidate';
import { label, dateFormat } from './filters';
Vue.config.productionTip = false;

//Vue.use(BootstrapVue)
Vue.component('BAlert', BAlert);
Vue.component('BButton', BButton);
Vue.component("BCard", BCard);
Vue.component("BOverlay", BOverlay);
Vue.component("BContainer", BContainer);
Vue.component("BCollapse", BCollapse);
Vue.component('BForm', BForm);
Vue.component('BFormRow', BFormRow);
Vue.component("BFormGroup", BFormGroup);
Vue.component("BFormInput", BFormInput);
Vue.component("BFormInvalidFeedback", BFormInvalidFeedback);
Vue.component('BNavbar', BNavbar);
Vue.component('BNavbarBrand', BNavbarBrand);
Vue.component('BNavbarNav', BNavbarNav);
Vue.component('BNavbarToggle', BNavbarToggle);
Vue.component('BDropdownItem', BDropdownItem);
Vue.component('BNavItemDropdown', BNavItemDropdown);
Vue.component('BPagination', BPagination);
Vue.component('BRow', BRow);
Vue.component('BCol', BCol);
Vue.component('BModal', BModal);
Vue.component('BSpinner', BSpinner);
Vue.component('BPagination', BPagination);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BFormSelectOption', BFormSelectOption);
Vue.component('BFormDatepicker', BFormDatepicker);
Vue.component('BInputGroup', BInputGroup);
Vue.component('BInputGroupAppend', BInputGroupAppend);
Vue.component('BFormCheckbox', BFormCheckbox);
Vue.component('BFormRadio', BFormRadio);
Vue.component('BFormCheckboxGroup', BFormCheckboxGroup);
Vue.component('BFormTextarea', BFormTextarea);
Vue.component('BBadge', BBadge);
Vue.use(BootstrapVueIcons)
Vue.use(Vuelidate)
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
