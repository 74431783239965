import { RecordSet, TestInstance } from '@/types'
import request from './request'

export default {
  async list(query: Record<string, any> = {page:1, name:"", dropdown:false}): Promise<RecordSet<TestInstance>>{
    return await request(process.env.VUE_APP_API_URL + "/services/test-instances", {method:"GET", query: query})
  },
  async get(id: string): Promise<TestInstance>{
    return await request(process.env.VUE_APP_API_URL + "/services/test-instances/"+encodeURIComponent(id), {method:"GET"})
  },
  async update(id: string, data: Record<string, any>): Promise<TestInstance>{
    return await request(process.env.VUE_APP_API_URL + "/services/test-instances/"+encodeURIComponent(id), {method:"POST", body:data})
  },
  async recalculate(id: string, data: Record<string, any>): Promise<TestInstance>{
    return await request(process.env.VUE_APP_API_URL + "/services/test-instances/"+encodeURIComponent(id)+"/recalculate", {method:"POST"})
  },
  async respond(id: string, question: string, response: string): Promise<TestInstance>{
    return await request(process.env.VUE_APP_API_URL + "/services/test-instances/"+encodeURIComponent(id)+"/respond", {method:"POST", body:{question, response}})
  },
  async complete(id: string): Promise<TestInstance>{
    return await request(process.env.VUE_APP_API_URL + "/services/test-instances/"+encodeURIComponent(id)+"/complete", {method:"POST"})
  },
  async download(id: string): Promise<Blob>{
    return await request(process.env.VUE_APP_API_URL + "/services/test-instances/"+encodeURIComponent(id)+"/download", {method:"GET", blob:true})
  },
  async chart(id: string): Promise<Blob>{
    return await request(process.env.VUE_APP_API_URL + "/services/test-instances/"+encodeURIComponent(id)+"/chart", {method:"GET", blob:true})
  }
}