import AuthAPI from "./auth";
import companiesAPI from './companies';
import campaignsAPI from './campaigns';
import smartProfilesAPI from './smartProfiles';
import usersAPI from './users';
import importAPI from './import';
import testDefsAPI from './testDefs';
import testInstancesAPI from './testInstances';
import testLocalesAPI from './testLocales';
import linksAPI from './links';
import smartSearchesAPI from './smartSearches';
export default {
  auth: AuthAPI,
  companies: companiesAPI,
  campaigns: campaignsAPI,
  smartProfiles: smartProfilesAPI,
  users: usersAPI,
  import: importAPI,
  testDefs: testDefsAPI,
  testInstances: testInstancesAPI,
  testLocales: testLocalesAPI,
  links: linksAPI,
  smartSearches: smartSearchesAPI,
};
