import { SmartProfileForm } from '@/models/SmartProfile'
import { SearchSmartProfileForm } from '@/types'
import request from './request'

export default {
  async list(page = 1, q: SearchSmartProfileForm, dropdown = false) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-profiles", { method: "GET", query: { ...q, page, dropdown } })
  },
  async get(id: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-profiles/" + encodeURIComponent(id), { method: "GET" })
  },
  async create(smartProfile: SmartProfileForm) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-profiles", { method: "POST", body: { ...smartProfile, _id: undefined } })
  },
  async update(smartProfile: SmartProfileForm) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-profiles/" + encodeURIComponent(smartProfile._id), { method: "POST", body: { ...smartProfile, _id: undefined } })
  },
  async duplicate(smartProfile: SmartProfileForm) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-profiles/" + encodeURIComponent(smartProfile._id) + "/duplicate", { method: "POST", body: { ...smartProfile, _id: undefined } })
  },
  async invite(id: string, emails: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-profiles/" + encodeURIComponent(id) + "/invite", { method: "POST", body: { emails } })
  },
  async delete(smartProfile: SmartProfileForm) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-profiles/" + encodeURIComponent(smartProfile._id), { method: "DELETE" })
  },
  async nameAvailable(name: string, id?: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-profiles/available-name", { method: "POST", body: { _id: id, name } })
  }
}