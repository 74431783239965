import store from '@/store'
import dateformat from "dateformat"
const splitRegEx = /(\$\{[a-zA-Z0-9\.]+\})/g
const splitDotRegEx = /\./g
export const evalValue = (value: string, context: Record<string, any>) => {
  const values = value.split(splitDotRegEx).reverse();
  let ctx: any = context;
  while (values.length) {
    const v = values.pop()!;
    if (typeof ctx[v] === "undefined") return;
    ctx = ctx[v];
  }
  return ctx as string;
}
export const label = (value: string, context: Record<string, any> = {}) => {
  const { labels } = store.state;
  const l = labels[value] || value;
  const values = l.split(splitRegEx);
  for (let i = 0; i < values.length; i++) {
    if (values[i].match(splitRegEx)) {
      const toReplace = values[i].slice(2, values[i].length - 1).trim();
      const result = evalValue(toReplace, context);
      values[i] = typeof result === "undefined" ? `\${${toReplace}}` : result;
    }
  }
  return values.join("");
}
export const dateFormat = (format: string, date: Date) => {
  return date ? dateformat(date, format) : "-"
}

export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}