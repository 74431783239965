import { Company } from '@/models/Company'
import request from './request'

export default {
  async valid(id: string): Promise<boolean> {
    return await request(process.env.VUE_APP_API_URL + "/services/companies", { method: "POST", body: { _id: id } })
  },
  async get(id: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/links/" + encodeURIComponent(id), { method: "GET" })
  },
}