import {
  Mark
} from 'tiptap'
import {
  updateMark,
  markInputRule
} from 'tiptap-commands';

export default class Alignment extends Mark {

  get name() {
    return 'alignment';
  }

  get defaultOptions() {
    return {
      levels: ["left", "center", "right", "justify"]
    };
  }

  get schema() {
    return {
      attrs: {
        align: {
          default: 'left',
        },
      },
      parseDOM: [{
        style: 'text-align',
        getAttrs: (value: any) => ({
          align: value || "left"
        }),
      },],
      toDOM: (mark: any) => {
        return ["span", {
          class: `block text-${mark.attrs.align}`
        }, 0];
      }
    };
  }

  commands({
    type
  }: any) {
    return (attrs: any) => updateMark(type, attrs);
  }

  inputRules({
    type
  }: any) {
    return [
      markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type),
    ];
  }
}