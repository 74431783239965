import { Campaign, CampaignForm, CampaignStatusRecord } from '@/models/Campaign'
import { SearchCampaignForm } from '@/types'
import request from './request'

export default {
  async list(page = 1, q: SearchCampaignForm, dropdown = false) {
    return await request(process.env.VUE_APP_API_URL + "/services/campaigns", { method: "GET", query: { ...q, page, dropdown } })
  },
  async get(id: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/campaigns/" + encodeURIComponent(id), { method: "GET" })
  },
  async getStatus(id: string): Promise<CampaignStatusRecord[]> {
    return await request(process.env.VUE_APP_API_URL + "/services/campaigns/" + encodeURIComponent(id) + "/status", { method: "GET" })
  },
  async create(campaign: CampaignForm) {
    return await request(process.env.VUE_APP_API_URL + "/services/campaigns", { method: "POST", body: { ...campaign, _id: undefined } })
  },
  async update(campaign: CampaignForm) {
    return await request(process.env.VUE_APP_API_URL + "/services/campaigns/" + encodeURIComponent(campaign._id), { method: "POST", body: { ...campaign, _id: undefined } })
  },
  async invite(id: string, emails: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/campaigns/" + encodeURIComponent(id) + "/invite", { method: "POST", body: { emails } })
  },
  async delete(campaign: CampaignForm) {
    return await request(process.env.VUE_APP_API_URL + "/services/campaigns/" + encodeURIComponent(campaign._id), { method: "DELETE" })
  }
}