<template>
  <div class="rich-text-area border rounded-lg">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
      <div class="menubar border-bottom p-1">

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.bold() }"
          @click="commands.bold"
        >
          <b-icon icon="type-bold" />
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.italic() }"
          @click="commands.italic"
        >
          <b-icon icon="type-italic" />
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.strike() }"
          @click="commands.strike"
        >
          <b-icon icon="type-strikethrough" />
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.underline() }"
          @click="commands.underline"
        >
          <b-icon icon="type-underline" />
        </button>
        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: getMarkAttrs('alignment').align === 'left' }"
          @click="commands.alignment({ align: 'left' })"
        >
          <b-icon icon="text-left"></b-icon>
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: getMarkAttrs('alignment').align === 'center' }"
          @click="commands.alignment({ align: 'center' })"
        >
          <b-icon icon="text-center"></b-icon>
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: getMarkAttrs('alignment').align === 'right' }"
          @click="commands.alignment({ align: 'right' })"
        >
          <b-icon icon="text-right"></b-icon>
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: getMarkAttrs('alignment').align === 'justify' }"
          @click="commands.alignment({ align: 'justify' })"
        >
          <b-icon icon="text-paragraph"></b-icon>
        </button>
        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.code() }"
          @click="commands.code"
        >
          <b-icon icon="code" />
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.paragraph() }"
          @click="commands.paragraph"
        >
          <b-icon icon="paragraph" />
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <b-icon icon="list-ul" />
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <b-icon icon="list-ol" />
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.blockquote() }"
          @click="commands.blockquote"
        >
          <b-icon icon="chat-square-quote" />
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          :class="{ active: isActive.code_block() }"
          @click="commands.code_block"
        >
          <b-icon icon="code" />
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          @click="commands.horizontal_rule"
        >
          <b-icon icon="hr" />
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          @click="commands.undo"
        >
          <b-icon icon="arrow-counterclockwise" />
        </button>

        <button
          class="btn btn-sm btn-outline-secondary"
          @click="commands.redo"
        >
          <b-icon icon="arrow-clockwise" />
        </button>

      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>
<style lang="scss">
.block{
  display:block;
}
.rich-text-area{
  display:flex;
  flex-direction: column;
  & > .menubar{
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    & > button{
      margin: 0.125rem;
    }
  }
  & .editor__content{
    flex: 1 0;
    & > div{
      height: 100%;
      min-height: 120px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    & > div:focus{
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      outline: none;
    }
  }
}
</style>
<script>
import Vue from "vue"
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions';
import Alignment from "./tiptap/Alignment"

export default Vue.extend({
  props:["content"],
  components: {
    EditorContent,
    EditorMenuBar,
  },
  watch:{
    content(){
      this.editor.setContent(this.content);
    }
  },
  methods:{
    getHTML(){
      return this.editor.getHTML();
    },
    getJSON(){
      return this.editor.getJSON();
    }
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Alignment()
        ],
        content: this.content
      }),
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
});
</script>