import { render, staticRenderFns } from "./SmartSearch.vue?vue&type=template&id=53822476&scoped=true&"
import script from "./SmartSearch.vue?vue&type=script&lang=ts&"
export * from "./SmartSearch.vue?vue&type=script&lang=ts&"
import style0 from "./SmartSearch.vue?vue&type=style&index=0&id=53822476&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53822476",
  null
  
)

export default component.exports