import { SmartSearchFilterForm, SmartSearchForm } from '@/models/SmartSearch'
import request from './request'

export default {
  async list(page = 1, q: Record<string, any>, dropdown = false) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-searches", { method: "GET", query: { ...q, page, dropdown } })
  },
  async listRecords(id: any, page = 1, q: Record<string, any>, dropdown = false) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-searches/" + encodeURIComponent(id) + "/records", { method: "GET", query: { ...q, page, dropdown } })
  },
  async get(id: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-searches/" + encodeURIComponent(id), { method: "GET" })
  },
  async create(smartSearch: SmartSearchForm) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-searches", { method: "POST", body: { ...smartSearch, _id: undefined } })
  },
  async delete(id: any) {
    return await request(process.env.VUE_APP_API_URL + "/services/smart-searches/" + encodeURIComponent(id), { method: "DELETE" })
  }
}