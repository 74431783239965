import { User } from '@/store/types'
import request from './request'

export default {
  async list(query: Record<string, any> = {page:1}){
    return await request(process.env.VUE_APP_API_URL + "/services/users", {method:"GET", query: query})
  },
  async get(id: string){
    return await request(process.env.VUE_APP_API_URL + "/services/users/"+encodeURIComponent(id), {method:"GET"})
  },
  async create(user: User){
    return await request(process.env.VUE_APP_API_URL + "/services/users", {method:"POST", body: {...user, _id:undefined}})
  },
  async update(user: User){
    return await request(process.env.VUE_APP_API_URL + "/services/users/"+encodeURIComponent(user._id), {method:"POST", body: {...user, _id:undefined}})
  },
  async privacy(value: boolean){
    return await request(process.env.VUE_APP_API_URL + "/services/users/privacy", {method:"POST", body: {value}})
  },
  async delete(user: User){
    return await request(process.env.VUE_APP_API_URL + "/services/users/"+encodeURIComponent(user._id), {method:"DELETE"})
  },
  async availableUsername(username: string, id?: string){
    return await request(process.env.VUE_APP_API_URL + "/services/users/available-username", {method:"POST", body: {_id:id, username}})
  }
}