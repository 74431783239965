<script>
import { Doughnut, mixins } from "vue-chartjs";
// 1. Import Chart.js so you can use the global Chart object
import Chart from "chart.js";
// 2. Import the `generateChart()` method to create the vue component.
import { generateChart } from "vue-chartjs";
const Gauge = generateChart("gauge-chart", "gauge");
export default {
  extends: Gauge,
  props: ["options"],
  mixins: [mixins.reactiveProp],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>