import { RecordSet, TestDef } from '@/types'
import request from './request'

export default {
  async list(query: Record<string, any> = {page:1, name:"", dropdown:false}): Promise<RecordSet<TestDef>>{
    return await request(process.env.VUE_APP_API_URL + "/services/test-defs", {method:"GET", query: query})
  },
  async get(id:  string){
    return await request(process.env.VUE_APP_API_URL + "/services/test-defs/"+encodeURIComponent(id), {method:"GET"})
  },
  async deleteFormula(id: string, locale: string, formula: any){
    return await request(process.env.VUE_APP_API_URL + "/services/test-defs/"+encodeURIComponent(id)+"/formula", {method:"DELETE", body:formula});
  },
  async insertOrUpdateFormula(id: string, locale: string, body: any){
    return await request(process.env.VUE_APP_API_URL + "/services/test-defs/"+encodeURIComponent(id)+"/formula/"+encodeURIComponent(locale), {method:"POST", body});
  },
  async testFormulas(id: string, locale: string, points: Record<string, number>){
    return await request(process.env.VUE_APP_API_URL + "/services/test-defs/"+encodeURIComponent(id)+"/test-formulas/"+encodeURIComponent(locale), {method:"POST", body:{points}});
  }
}