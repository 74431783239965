import request from './request';

export default {
  async login(username: string, password: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/auth/login", { body: { username, password } });
  },
  async refresh(refreshToken: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/auth/refresh", { body: { refreshToken } });
  },
  async forgotPassword(email: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/auth/forgot-password", { body: { email } });
  },
  async resetPassword(id: string, password: string, confirmPassword: string) {
    return await request(process.env.VUE_APP_API_URL + "/services/auth/reset-password", { body: {_id: id, password, confirmPassword } });
  }
};
