import { Company } from '@/models/Company'
import request from './request'

export default {
  async list(page=1, term="", dropdown=false){
    return await request(process.env.VUE_APP_API_URL + "/services/companies", {method:"GET", query: {page, term, dropdown}})
  },
  async get(id: string){
    return await request(process.env.VUE_APP_API_URL + "/services/companies/"+encodeURIComponent(id), {method:"GET"})
  },
  async create(company: Company){
    return await request(process.env.VUE_APP_API_URL + "/services/companies", {method:"POST", body: {...company, _id:undefined}})
  },
  async update(company: Company){
    return await request(process.env.VUE_APP_API_URL + "/services/companies/"+encodeURIComponent(company._id), {method:"POST", body: {...company, _id:undefined}})
  },
  async delete(company: Company){
    return await request(process.env.VUE_APP_API_URL + "/services/companies/"+encodeURIComponent(company._id), {method:"DELETE"})
  },
  async availableName(name: string, id?: string){
    return await request(process.env.VUE_APP_API_URL + "/services/companies/available-name", {method:"POST", body: {_id:id, name}})
  }
}