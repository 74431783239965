import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import ImportTest from "../views/ImportTest.vue";
import ImportResponses from "../views/ImportResponses.vue";
import ImportCompany from "../views/ImportCompany.vue";
import Campaign from "../views/Campaign.vue";
import Campaigns from "../views/Campaigns.vue";
import Companies from "../views/Companies.vue";
import Company from "../views/Company.vue";
import Users from "../views/Users.vue";
import User from "../views/User.vue";
import Formulas from "../views/Formulas.vue";
import FormulaTest from "../views/FormulaTest.vue";
import Formula from "../views/Formula.vue";
import SmartProfiles from "../views/SmartProfiles.vue";
import SmartProfile from "../views/SmartProfile.vue";
import Test from "../views/Test.vue";
import TestResults from "../views/TestResults.vue";
import TestResult from "../views/TestResult.vue";
import TestPrint from "../views/TestPrint.vue";
import SmartSearches from "../views/SmartSearches.vue";
import SmartSearch from "../views/SmartSearch.vue";
import Privacy from "../views/Privacy.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import TestSummary from "../views/TestSummary.vue";
import TestQuestion from "../views/TestQuestion.vue";
import Enroll from "../views/Enroll.vue";
import { UserType } from '@/models/User';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/import/test",
    name: "ImportTest",
    component: ImportTest,
    meta: {
      title: "Import Test"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user && store.getters.user.type === UserType.SUPERADMIN) next()
      else next("/login");
    }
  },
  {
    path: "/import/company",
    name: "ImportCompany",
    component: ImportCompany,
    meta: {
      title: "Import Company"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user && store.getters.user.type === UserType.SUPERADMIN) next()
      else next("/login");
    }
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Welcome"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user) next()
      else next("/login");
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login"
    }
  },
  {
    path: "/test/:id",
    name: "Test",
    component: Test,
    meta: {
      title: "Test"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user) next()
      else next("/login");
    }
  },
  {
    path: "/test/:id/summary",
    name: "Test Summary",
    component: TestSummary,
    meta: {
      title: "Test"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user) next()
      else next("/login");
    }
  },
  {
    path: "/test/:id/import",
    name: "Import Test Responses",
    component: ImportResponses,
    meta: {
      title: "Import Test Responses"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user) next()
      else next("/login");
    }
  },
  {
    path: "/test/:id/:q",
    name: "Test Question",
    component: TestQuestion,
    meta: {
      title: "Test Question"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user) next()
      else next("/login");
    }
  },
  {
    path: "/registry/companies/:id",
    name: "Company",
    component: Company,
    meta: {
      title: "Dettaglio Azienda"
    },
    beforeEnter(to, from, next) {
      if (store.getters.isSuperAdmin) next()
      else next("/login");
    }
  },
  {
    path: "/registry/companies",
    name: "Companies",
    component: Companies,
    meta: {
      title: "Elenco Aziende/Gruppi"
    },
    beforeEnter(to, from, next) {
      if (store.getters.isSuperAdmin) next()
      else next("/login");
    }
  },
  {
    path: "/registry/users/:id",
    name: "User",
    component: User,
    meta: {
      title: "Dettaglio Utente"
    },
    beforeEnter(to, from, next) {
      if (store.getters.isAtLeastLeader) next()
      else next("/login");
    }
  },
  {
    path: "/registry/users",
    name: "Users",
    component: Users,
    meta: {
      title: "Elenco Utenti"
    },
    beforeEnter(to, from, next) {
      if (store.getters.isAtLeastLeader) next()
      else next("/login");
    }
  },
  {
    path: "/registry/campaigns/:id",
    name: "Campaign",
    component: Campaign,
    meta: {
      title: "Dettaglio Campagna"
    },
    beforeEnter(to, from, next) {
      if (store.getters.isAtLeastLeader) next()
      else next("/login");
    }
  },
  {
    path: "/registry/campaigns",
    name: "Campaigns",
    component: Campaigns,
    meta: {
      title: "Elenco Campagne"
    },
    beforeEnter(to, from, next) {
      if (store.getters.isAtLeastLeader) next()
      else next("/login");
    }
  },

  {
    path: "/configuration/formulas",
    name: "Formulas",
    component: Formulas,
    meta: {
      title: "Formule"
    },
    beforeEnter(to, from, next) {
      if (store.getters.isSuperAdmin) next()
      else next("/login");
    }
  },
  {
    path: "/configuration/formulas/test",
    name: "Formulas Test",
    component: FormulaTest,
    meta: {
      title: "Test Formule"
    },
    beforeEnter(to, from, next) {
      if (store.getters.isSuperAdmin) next()
      else next("/login");
    }
  },
  {
    path: "/configuration/formulas/formula",
    name: "Formula",
    component: Formula,
    meta: {
      title: "Formula"
    },
    beforeEnter(to, from, next) {
      if (store.getters.isSuperAdmin) next()
      else next("/login");
    }
  },

  {
    path: "/configuration/smartProfiles",
    name: "Smart Profiles",
    component: SmartProfiles,
    meta: {
      title: "Profili Smart"
    },
    beforeEnter(to, from, next) {
      if (store.getters.isSuperAdmin) next()
      else next("/login");
    }
  },
  {
    path: "/configuration/smartProfiles/:id",
    name: "Smart Profile",
    component: SmartProfile,
    meta: {
      title: "Profilo Smart"
    },
    beforeEnter(to, from, next) {
      if (store.getters.isSuperAdmin) next()
      else next("/login");
    }
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      title: "Privacy"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user) next()
      else next("/login");
    }
  },
  {
    path: "/report/results",
    name: "Test Results",
    component: TestResults,
    meta: {
      title: "Risultati Utente"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user && store.getters.canDoReport) next()
      else next("/login");
    }
  },
  {
    path: "/report/results/:id",
    name: "Test Result",
    component: TestResult,
    meta: {
      title: "Esito Test"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user && store.getters.canDoReport) next()
      else next("/login");
    }
  },
  {
    path: "/report/results/:id/print",
    name: "Test Print",
    component: TestPrint,
    meta: {
      title: "Stampa Report"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user && store.getters.canDoReport) next()
      else next("/login");
    }
  },
  {
    path: "/report/smartSearches",
    name: "Smart Searches",
    component: SmartSearches,
    meta: {
      title: "Lista Ricerche Smart"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user && store.getters.canDoReport) next()
      else next("/login");
    }
  },
  {
    path: "/report/smartSearches/new",
    name: "Smart Search",
    component: SmartSearch,
    meta: {
      title: "Ricerca Smart"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user && store.getters.canDoReport) next()
      else next("/login");
    }
  },
  {
    path: "/report/smartSearches/:id",
    name: "Smart Search",
    component: SmartSearch,
    meta: {
      title: "Ricerca Smart Risultati"
    },
    beforeEnter(to, from, next) {
      if (store.getters.user && store.getters.canDoReport) next()
      else next("/login");
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPassword,
    meta: {
      title: "Password dimenticata"
    }
  },
  {
    path: "/reset-password/:id",
    name: "Reset Password",
    component: ResetPassword,
    meta: {
      title: "Reset Password"
    }
  },
  {
    path: "/enroll/:id",
    name: "Enroll",
    component: Enroll,
    meta: {
      title: "Registrati"
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  //store.dispatch("menuToggle"); // close menu on page change
  if (to.path !== "/login" && to.path !== "/privacy") {
    if (store.getters.user && !store.getters.user.privacy) {
      next("/privacy");
      return;
    }
  }
  const pageTitle = to.meta?.title || ""
  const title = (process.env.VUE_APP_TITLE || "App")
  document.title = [pageTitle, title].join(" - ");
  store.dispatch("interaction");
  next();
});

export default router;
